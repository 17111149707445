import { getAction, postAction } from './request';

const loginAccount = (params) => postAction('/property/login', { data: params });
const logoutAccount = () => getAction('/property/logout');

const selectCarInfoList = (params) => getAction('/p/list/car', { params: params });
const exportInfoList = (url, params) => postAction(url, { params: params, responseType: 'blob' });
const obtainBalanceHistory = (params) => getAction('/v1/open/balance/history', { params: params });

const obtainCouponGoodsList = (params) => getAction('/v1/open/goods/coupon/list', { params: params });
const obtainRechargeGoodsList = (params) => getAction('/v1/open/goods/recharge/list', { params: params });
const obtainGasGoodsList = (params) => getAction('/v1/open/goods/gas/list', { params: params });
const obtainCnpcOrderList = (params) => getAction('/v1/open/order/cnpc/list', { params: params });
const obtainCnpcOrderDetailList = (params) => getAction('/v1/open/order/cnpc/list/detail', { params: params });
const contactUsPost = (params) => postAction('/v1/open/contactUs', { data: params });

export {
    loginAccount,
    logoutAccount,
    selectCarInfoList,
    exportInfoList,
    obtainBalanceHistory,
    obtainRechargeGoodsList,
    obtainGasGoodsList,
    obtainCouponGoodsList,
    obtainCnpcOrderList,
    obtainCnpcOrderDetailList,
    contactUsPost
};
