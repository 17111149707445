// assets
import { DashboardOutlined, CarOutlined } from '@ant-design/icons';
// import EmojiTransportationOutlinedIcon from '@mui/icons-material/EmojiTransportationOutlined';

// icons
const icons = {
    DashboardOutlined,
    CarOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: '基础信息',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.CarOutlined,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
