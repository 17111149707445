import axios from 'axios';
import { access_token, tenant_id } from '../store/mutation-types';
import signMd5Utils from '../store/signMd5Utils';

const client = axios.create({
    baseURL: 'https://api.oil.kuaichuankeji.com/apis',
    // baseURL: 'http://localhost:8080/apis',
    timeout: 15000,
    responseType: 'json'
});

client.interceptors.request.use(
    (config) => {
        // 最简单的方案
        config.url = config.url.replace('{version}', 'v1');

        const token = window.localStorage.getItem(access_token) || window.sessionStorage.getItem(access_token);
        if (token) {
            config.headers[access_token] = token;
        }

        const tenantId = window.localStorage.getItem(tenant_id) || window.sessionStorage.getItem(tenant_id);
        config.headers[tenant_id] = tenantId ? tenantId : 0;
        if (config.method === 'get') {
            config.params = {
                _t: new Date().getTime(),
                ...config.params
            };
        }
        config.data = { ...config.data };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

client.interceptors.response.use(
    (res) => {
        return res.data;
    },
    (error) => {
        if (error.response) {
            let data = error.response.data;
            const token = window.localStorage.getItem(access_token) || window.sessionStorage.getItem(access_token);
            console.log('------异常响应------', token);
            console.log('------异常响应------', error.response.status);
            switch (error.response.status) {
                case 403:
                    break;
                case 500:
                    console.log('------error.response------', error.response);
                    // update-begin- --- author:liusq ------ date:20200910 ---- for:处理Blob情况----
                    let type = error.response.request.responseType;
                    if (type === 'blob') {
                        break;
                    }
                    if (data.message.includes('Token失效')) {
                        window.localStorage.clear();
                        window.location.href = '/';
                    }
                    break;
                case 404:
                    break;
                case 504:
                    break;
                case 401:
                    // 返回首页 先简单粗暴的这么搞
                    window.localStorage.clear();
                    window.location.href = '/';
                    break;
                default:
                    break;
            }
        } else if (error.message) {
            if (error.message.includes('timeout')) {
            } else {
            }
        }
        return Promise.reject(error);
    }
);

export async function getAction(url, config) {
    return await client.request({ url, method: 'get', ...config });
}

export async function postAction(url, config) {
    let sign = signMd5Utils.getSign(url, config.data);
    //将签名和时间戳，添加在请求接口 Header
    let signHeader = { 'X-Sign': sign, 'X-TIMESTAMP': signMd5Utils.getDateTimeToString() };
    config.headers = {
        'content-type': 'application/json',
        ...signHeader
    };

    return await client.request({ url, method: 'post', ...config });
}

export default client;
